<template>
  <HeaderComponent></HeaderComponent>
  <div class="background">
    <div class="presentation">
      <div class="margins-left margins-top">
        <h1>Proyectos</h1>
        <div :class="'displa'">
          <RegisterProyectsComponent v-for="registro in registerProyects" :key="registro.id" :title="registro.title"
            :description="registro.description" :tecnology="registro.tecnology" :image="registro.image"
            :url="registro.url"></RegisterProyectsComponent>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import HeaderComponent from '../components/HeaderComponent.vue';
import RegisterProyectsComponent from '../components/RegisterProyectsComponent.vue'

export default {
  name: 'ProyectosPage',
  components: {
    HeaderComponent,
    RegisterProyectsComponent,
  },
  data() {
    return {
      registerProyects: [
        { id: 1, title: 'AprexiApp', description: 'Aprexi es una innovadora plataforma que actúa como un puente dinámico entre individuos en busca de empleo o nuevas oportunidades y las empresas que buscan talento. Su enfoque multisectorial no se limita a nichos específicos, sino que se abre a una amplia gama de industrias.', tecnology: 'Android/Kotlin', image: '/src/assets/images/oo.png', altImage: 'Aprexi App', url: 'aprexiAndroid' },
        { id: 2, title: 'AprexiWeb', description: 'Aprexi es una innovadora plataforma que actúa como un puente dinámico entre individuos en busca de empleo o nuevas oportunidades y las empresas que buscan talento. Su enfoque multisectorial no se limita a nichos específicos, sino que se abre a una amplia gama de industrias.', tecnology: 'Vue.js', image: '/src/assets/images/homeweb.jpg', altImage: 'Aprexi Web', url: 'aprexiWeb' },
        { id: 3, title: 'IoT Planning - Agricultura 4.0', description: 'Plataforma para gestionar dispositivos IoT en agricultura 4.0, diseñada con tecnología LoRaWAN y ESP32, que optimiza cultivos con monitoreo avanzado y análisis en tiempo real.', tecnology: 'Vue.js', image: '/src/assets/images/homeIotPlanning.png', altImage: 'Iot Planning', url: 'iotPlanning' },
        { id: 4, title: 'AprexiApp', description: 'Aprexi es una innovadora plataforma que actúa como un puente dinámico entre individuos en busca de empleo o nuevas oportunidades y las empresas que buscan talento. Su enfoque multisectorial no se limita a nichos específicos, sino que se abre a una amplia gama de industrias.', tecnology: 'iOS/Swift', image: '/src/assets/images/swift.png', altImage: 'Aprexi App', url: 'aprexiIOS' },
        { id: 5, title: 'AprexiApp', description: 'Aprexi es una innovadora plataforma que actúa como un puente dinámico entre individuos en busca de empleo o nuevas oportunidades y las empresas que buscan talento. Su enfoque multisectorial no se limita a nichos específicos, sino que se abre a una amplia gama de industrias.', tecnology: 'Flutter/Dart', image: '/src/assets/images/flutter.png', altImage: 'Aprexi App', url: 'aprexiFlutter' },
        { id: 6, title: 'API BackEnd', description: 'Aprexi es una innovadora plataforma que actúa como un puente dinámico entre individuos en busca de empleo o nuevas oportunidades y las empresas que buscan talento. Su enfoque multisectorial no se limita a nichos específicos, sino que se abre a una amplia gama de industrias.', tecnology: 'Node.js', image: '/src/assets/images/node.png', altImage: 'Node BackEnd', url: 'backEndNodeJs' },
      ],
    };
  },
};
</script>

<script setup>

</script>

<style>
@import url('../assets/styles/displacements.css');
@import url('../assets/styles/gravity.css');
@import url('../assets/styles/styles_colors.css');
@import url('../assets/styles/styles_letter.css');
@import url('../assets/styles/styles_margins.css');
@import url('../assets/styles/responsive_screen.css');

.displa {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
}
</style>