<template>
  <HeaderComponent></HeaderComponent>
  <div class="background">
    <div class="presentation" style=" flex-direction: column;">
      <h1 class="margins-left margins-top">IoT Planning</h1>
      <div style="display: flex; flex-direction: column;">
        <div class="margins-left" style="max-width: 1100px;">
          <Carousel>
            <Slide v-for="(image, index) in images" :key="index">
              <div class="carousel__item">
                <img :src="image" :alt="'Imagen ' + (index + 1)" />
              </div>
            </Slide>

            <template #addons>
              <Navigation />
              <Pagination />
            </template>
          </Carousel>
        </div>
        <div :class="'descripcion-proyecto'">
          <h2>¿Qué es IoT Planning?</h2>
          <p class="margin-top">IoT Planning es un innovador proyecto diseñado para optimizar los procesos agrícolas
            mediante
            tecnología de precisión o agricultura 4.0. Esta plataforma combina hardware avanzado, como placas PCB
            personalizadas
            con tecnología de radiofrecuencia LoRaWAN, y una web intuitiva para el análisis y control de dispositivos en
            tiempo
            real.</p>
          <p class="margin-top">El sistema es capaz de conectar múltiples sensores agrícolas a través de un gateway o,
            dependiendo del número de dispositivos, enviar datos directamente al servidor MQTT HiveMQ, donde se
            almacenan y
            gestionan en una base de datos centralizada.</p>
          <div :style="{ padding: '0px', marginTop: '30px' }">
            <h2>Ventajas de la Agricultura 4.0</h2>
            <p class="margin-top">La agricultura 4.0 revoluciona el sector agrícola mediante la integración de
              tecnología
              avanzada. Las principales ventajas incluyen:</p>
            <ul>
              <li class="margin-top">🌱 <strong>Ahorro de recursos:</strong>
                <p>Optimización del uso de agua, fertilizantes y energía, reduciendo costos y desperdicios.</p>
              </li>
              <li class="margin-top">📈 <strong>Mayor productividad:</strong>
                <p>Mejora de los rendimientos de los cultivos al monitorear y ajustar condiciones en tiempo real.</p>
              </li>
              <li class="margin-top">🌍 <strong>Sostenibilidad:</strong>
                <p>Prácticas agrícolas más responsables con el medio ambiente, reduciendo la huella ecológica.</p>
              </li>
              <li class="margin-top">🚨 <strong>Prevención de problemas:</strong>
                <p>Alertas tempranas ante condiciones adversas como sequías, enfermedades o plagas.</p>
              </li>
              <li class="margin-top">📊 <strong>Toma de decisiones basada en datos:</strong>
                <p>Análisis preciso para maximizar la eficiencia y calidad de los cultivos.</p>
              </li>

            </ul>
          </div>
          <div :style="{ padding: '0px', marginTop: '30px' }">
            <h2>Hardware y Diseño del Sistema</h2>
            <p class="margin-top">El hardware de IoT Planning fue diseñado utilizando EasyEDA, integrando sensores
              personalizados con ESP32 como procesador principal. Estos dispositivos recopilan datos clave como humedad,
              temperatura y pH del suelo, y transmiten esta información mediante LoRaWAN.</p>
            <p class="margin-top">Este diseño modular asegura la escalabilidad del sistema y la capacidad de adaptarse a
              diferentes escenarios agrícolas, como cultivos hidropónicos, viñedos y horticultura intensiva.</p>
          </div>
          <div :style="{ padding: '0px', marginTop: '30px' }">
            <h2>Funciones de la Plataforma Web</h2>
            <p class="margin-top">La plataforma web de IoT Planning, desarrollada con tecnologías modernas como Vue.js,
              Vuetify
              y Leaflet, ofrece una experiencia completa para la gestión de dispositivos y análisis de datos. Entre sus
              principales funcionalidades se incluyen:</p>
            <ul>
              <li class="margin-top">📊 <strong>Análisis de datos en tiempo real:</strong>
                <p> Visualización intuitiva de métricas clave
                  para la toma de decisiones informadas.</p>
              </li>
              <li class="margin-top">🗺 <strong>Mapa interactivo:</strong>
                <p> Localización geográfica de dispositivos en tiempo real
                  para un monitoreo eficiente.</p>
              </li>
              <li class="margin-top">🚨 <trong>Alertas personalizadas:</trong>
                <p>Notificaciones inmediatas ante condiciones adversas
                  detectadas por los sensores.</p>
              </li>
              <li class="margin-top">⚙ <strong>Gestión de dispositivos:</strong>
                <p>Supervisión del estado de los sensores,
                  configuración remota y actualización de firmware.</p>
              </li>
            </ul>
          </div>
          <div :style="{ padding: '0px', marginTop: '30px' }">
            <h2>Tecnologías Aplicadas</h2>
            <p class="margin-top">El desarrollo de IoT Planning se ha basado en un conjunto de herramientas y
              bibliotecas
              líderes que garantizan su robustez, escalabilidad y rendimiento:</p>
            <ul>
              <li class="margin-top">
                <h5><strong>Vue.js:</strong></h5>
                <p>Framework progresivo para construir interfaces de usuario
                  interactivas.</p>
              </li>
              <li class="margin-top"><strong>Vuetify:</strong>
                <p>Librería de componentes basada en Material Design.</p>
              </li>
              <li class="margin-top"><strong>Leaflet:</strong>
                <p>Librería para mapas interactivos y visualización
                  geográfica.</p>
              </li>
              <li class="margin-top"><strong>Axios:</strong>
                <p>Cliente HTTP para gestionar solicitudes y comunicaciones
                  con la API.</p>
              </li>
              <li class="margin-top"><strong>Chart.js:</strong>
                <p>Herramienta para crear gráficos dinámicos y
                  personalizables.</p>
              </li>
              <li class="margin-top"><strong>HiveMQ:</strong>
                <p>Servidor MQTT para la gestión eficiente de la mensajería
                  entre dispositivos IoT.</p>
              </li>
            </ul>
          </div>
          <div :class="'recuadro-tecnologias'">
            <div class="recuadro-proyectos">
              <h5>Diseño de PCB</h5>
            </div>
            <div class="recuadro-proyectos">
              <h5>Red LoRaWAN</h5>
            </div>
            <div class="recuadro-proyectos">
              <h5>Mapa Interactivo</h5>
            </div>
            <div class="recuadro-proyectos">
              <h5>API REST</h5>
            </div>
            <div> <img src="@/assets/icons/github.png" alt="Repositorio en GitHub"
                style="height: 100px; cursor: pointer;" @click="abrirEnlace"> </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HeaderComponent from '@/components/HeaderComponent.vue';
import 'vue3-carousel/dist/carousel.css';
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel';

export default {
  name: 'ProyectoBackEndNodejs',
  components: {
    HeaderComponent,
    Carousel,
    Slide,
    Pagination,
    Navigation,
  },
  methods: {
    abrirEnlace() {
      window.open('https://github.com/NicolasLavilla/BackEnd_Node.js/tree/backend_nodejs', '_blank');
    }
  },
  data() {
    return {
      images: [
        require('../../assets/images/proyects/proyectIotPlanning/Home.png'),
        require('../../assets/images/proyects/proyectIotPlanning/Dispositivos.png'),
        require('../../assets/images/proyects/proyectIotPlanning/Mapa.png'),
        require('../../assets/images/proyects/proyectIotPlanning/Alertas.png'),
        require('../../assets/images/proyects/proyectIotPlanning/Detalle Dispositivo.png'),
      ]
    }
  }
};
</script>

<script setup>
/*var descripcion1 = "Este proyecto de backend con Node.js es una plataforma robusta y escalable que sirve como motor para aplicaciones web y APIs. Utilizando Node.js, hemos construido un entorno de ejecución eficiente que permite el desarrollo rápido y ágil de soluciones backend. Nuestro enfoque se centra en la creación de una arquitectura sólida y flexible que garantice la seguridad, la escalabilidad y el rendimiento óptimo.";
var descripcion2 = "Con Node.js como base, hemos integrado tecnologías líderes como Express.js, Sequelize y Jsonwebtoken para gestionar las solicitudes HTTP, interactuar con la base de datos y garantizar la autenticación segura. Este proyecto backend es la columna vertebral de nuestra aplicación, proporcionando la infraestructura necesaria para soportar operaciones críticas y ofrecer una experiencia fluida a los usuarios finales.";
var descripcionAplicacion1 = "En este proyecto, hemos utilizado tecnologías líderes como Express.js, Jest, Sequelize y Jsonwebtoken para construir una aplicación web segura y eficiente en Node.js. Express.js nos proporciona una arquitectura sólida para manejar peticiones HTTP, Jest garantiza la calidad del código con pruebas unitarias, Sequelize simplifica la interacción con la base de datos y Jsonwebtoken asegura una autenticación segura. Con estas herramientas, he creado una aplicación confiable y de alto rendimiento.";
var descripcionApi1 = "La API REST desarrollada en Node.js ofrece una gama de servicios esenciales para la gestión de usuarios. Implementando métodos HTTP estándar como POST, PUT y GET, hemos creado una arquitectura sólida que abarca desde la autenticación hasta la obtención de datos de usuario.";
var descripcionApi2 = "Aunque estos puntos pueden parecer simples, detrás de cada uno de ellos hemos implementado una arquitectura completa que garantiza la seguridad, la eficiencia y la escalabilidad de nuestra API. Desde la gestión de rutas hasta la validación de datos y la integración con la base de datos, hemos construido una API robusta que cumple con los estándares de desarrollo modernos y proporciona una experiencia de usuario fluida y satisfactoria.";
*/</script>

<style>
@import url('@/assets/styles/displacements.css');
@import url('@/assets/styles/gravity.css');
@import url('@/assets/styles/styles_colors.css');
@import url('@/assets/styles/styles_letter.css');
@import url('@/assets/styles/styles_margins.css');
@import url('@/assets/styles/responsive_screen.css');
</style>