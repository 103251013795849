<template>
  <HeaderComponent></HeaderComponent>
  <div class="background">
    <div class="presentation">
      <div :class="'recuadro-presentacion-50-50'">
        <h2>Estudios</h2>
        <RegisterStudiesComponent v-for="registro in registrosEstudios" :key="registro.nombreEscuela"
          :nombreEscuela="registro.nombreEscuela" :urlEscuela="registro.urlEscuela"
          :ubicacionEscuela="registro.ubicacionEscuela" :ano="registro.ano" :curso="registro.curso"
          :descripcionCurso="registro.descripcionCurso" />
      </div>
      <div :class="'recuadro-presentacion-50-50'">
        <h2>Experiencia</h2>
        <RegisterExperienceComponent v-for="registro in registrosProfesionales" :key="registro.nombreEmpresa"
          :nombreEmpresa="registro.nombreEmpresa" :ubicacionEmpresa="registro.ubicacionEmpresa" :ano="registro.ano"
          :puesto="registro.puesto" :descripcionPuesto="registro.descripcionPuesto" />
      </div>
    </div>
  </div>
</template>

<script>
import HeaderComponent from '../components/HeaderComponent.vue';
import RegisterStudiesComponent from '@/components/RegisterStudiesComponent.vue';
import RegisterExperienceComponent from '@/components/RegisterExperienceComponent.vue';

export default {
  name: 'ExperienciaPage',
  components: {
    HeaderComponent,
    RegisterStudiesComponent,
    RegisterExperienceComponent,
  },

  data() {
    return {
      registrosEstudios: [
        { nombreEscuela: 'La Terminal', urlEscuela: 'https://la-terminal.es/', ubicacionEscuela: 'Zaragoza', ano: '2024-2024', curso: 'Programa de Emprendimiento', descripcionCurso: 'En el programa Takeoff de La Terminal, he aprendido:<br><div style="margin-left: 30px"><b>1.</b>Desarrollar un modelo de negocio sólido<br><b>2.</b>Crear un prototipo funcional<br><b>3.</b>Formar un equipo efectivo<br><b>4.</b>Conectar con el ecosistema emprendedor</div><br><b>¡He sido ganador del Programa!</b></br>' },
        { nombreEscuela: 'Hiberus', urlEscuela: 'https://www.hiberus.com/hiberus-university', ubicacionEscuela: 'Zaragoza', ano: '2023-2023', curso: 'Curso en tecnologias Móviles (Android, iOS, Flutter)', descripcionCurso: 'Este curso de 300h impartido por porfesionales de una consultora tecnológica, me permitieron aprender las últimas nociones de arquitectura, inyección de dependencias, tareas en segundo plano, entre otras muchas cosas de las distintas plataformas (Android, iOs y Flutter).' },
        { nombreEscuela: 'UNIR', urlEscuela: 'https://www.unir.net/', ubicacionEscuela: 'A distancia', ano: '2022-2023', curso: 'Grado Universitario en Ciencia de Datos', descripcionCurso: 'Estoy cursando el primer año del grado universitario de Ciencia de Datos en la universidad a distancia de la Rioja. Me apasiona el mundo del análisis de datos y el desarrollo de modelos estadísticos.' },
        { nombreEscuela: 'Ilerna', urlEscuela: 'https://www.ilerna.es/', ubicacionEscuela: 'Lleida', ano: '2021-2022', curso: 'G.S Desarrollo de Aplicaciones Multiplataforma', descripcionCurso: 'Estos estudios me permitieron diseñar, desarrollar y mantener de aplicaciones informáticas que puedan ejecutarse en diferentes plataformas, como dispositivos móviles, sistemas web y sistemas de escritorio.' },
        { nombreEscuela: 'SEAS', urlEscuela: 'https://www.seas.es/', ubicacionEscuela: 'Zaragoza', ano: '2020-2021', curso: 'Experto Universitario en IOT, Big Data e Industria 4.0', descripcionCurso: 'Al realizar este curso, pude conocer todo el entramado de tecnologías de telecomunicaciones que permite extraer información y adquirir el conocimiento de los datos para su posterior manipulación.' },
        { nombreEscuela: 'IES Bernat el Ferrer', urlEscuela: 'https://www.bernatelferrer.cat/', ubicacionEscuela: 'Molins de Rei', ano: '2018-2020', curso: 'G.S Automatización y Robótica Industrial', descripcionCurso: 'Estos estudios me capacitaron para diseñar y programar instalaciones automatizadas, desde su modelo de negocio hasta su intalación con robots y automatas' }
      ],

      registrosProfesionales: [
        { nombreEmpresa: 'Integra Tecnología', ubicacionEmpresa: 'Zaragoza', ano: '2024-Actualmente', puesto: 'Analista de Datos', descripcionPuesto: 'En este puesto, mi labor principal consiste en reunirme con clientes para recopilar información detallada y elaborar las memorias correspondientes a los Kits Digitales y Kits Consulting. Además, he desarrollado cuadros de mando interactivos con Power BI para el análisis de datos empresariales, abarcando todo el proceso: desde la extracción de datos a través de diversos medios hasta su visualización clara y efectiva para la toma de decisiones estratégicas' },
        { nombreEmpresa: 'Arco Electrónica', ubicacionEmpresa: 'La Almunia de Doña Godina', ano: '2023-2023', puesto: 'Desarrollador de Aplicaciones', descripcionPuesto: 'En este puesto desarrollé nuevos aplicativos web relacionados con el análisis de flotas, desde la creacion de Base de Datos, hasta el diseño de la interfaz y flujo de la aplicación. Fué una experiencia breve, pero aprendí mucho.' },
        { nombreEmpresa: 'Ibermática', ubicacionEmpresa: 'Bilbao', ano: '2022-2023', puesto: 'Desarrollador Web Java Junior', descripcionPuesto: 'Aprendí muchisimo del maravilloso equipo que me acogió. Realizé mantenimientos de la plataforma, utilicé frameworks de Java como Struts2, un SGBD Oracle y Subversion como control de versiones.' }
      ],
    };
  },
};


</script>

<style>
@import url('../assets/styles/displacements.css');
@import url('../assets/styles/gravity.css');
@import url('../assets/styles/styles_colors.css');
@import url('../assets/styles/styles_letter.css');
@import url('../assets/styles/styles_margins.css');
@import url('../assets/styles/responsive_screen.css');
</style>